<template>
    <div class="relative ">
        <div class="absolute background-div loading mt-4 ">
            <div class="text-center text-3xl my-6">{{ $t('message.performance_timings_measured') }}</div>
            <div class="flex items-center my-6 mx-auto justify-center">
                <div class="loading-bg absolute"></div>
                <div class="loading-progress absolute"></div>
            </div>
        </div>
        
        <div class="absolute background-div invisible" ref="arrow">
            <div class="dots flex items-center mt-4 mx-auto metrics-container "></div>
            <div class="arrow flex items-center mb-4 mx-auto"></div>
        </div>
        <div class="mx-auto flex flex-row items-center justify-between my-4 relative metrics-container">
            <div class="flex justify-around my-4 icon-div icon-loader items-center" v-if="serverLoading">
                <hollow-dots-spinner  :animation-duration="1250" :size="40" :color="'#444444'" />
            </div>
            <div v-else class="flex flex-col items-center justify-around my-4 icon-div "  ref="server" :delay="metrics.server">
                <p class="text-2xl icon-description">Webserver</p>
                <b-icon-server font-scale="6" class="icon metrics-icon"></b-icon-server>
                <p class="text-2xl">{{ metrics.server }}ms</p>
            </div>
            
            <div class="flex justify-around y-4 icon-div icon-loader items-center" v-if="appLoading">
                <hollow-dots-spinner  :animation-duration="1250" :size="40" :color="'#444444'" />
            </div>
            <div v-else class="flex flex-col items-center justify-around my-4 icon-div " ref="app" :delay="metrics.app" >
                <p class="text-2xl icon-description">Application</p>
                <b-icon-box font-scale="6" class="icon metrics-icon"></b-icon-box>
                <p class="text-2xl">{{ metrics.app }}ms</p>
            </div>
            
            <div class="flex justify-around y-4 icon-div icon-loader items-center" v-if="browserLoading">
                <hollow-dots-spinner  :animation-duration="1250" :size="40" :color="'#444444'" />
            </div>
            <div v-else class="flex flex-col items-center justify-around my-4 icon-div " ref="browser" :delay="metrics.browser" >
                <p class="text-2xl icon-description">Browser</p>
                <b-icon-window font-scale="6" class="icon metrics-icon"></b-icon-window>
                <p class="text-2xl">{{ metrics.browser }}ms</p>
            </div>
        </div>
    </div>
</template>
<script>

import { BIconServer , BIconWindow , BIconBox } from 'bootstrap-vue'
import { HollowDotsSpinner } from 'epic-spinners'

export default ({
    components:{
        BIconServer,
        BIconWindow,
        BIconBox,
        HollowDotsSpinner
    },
    props:{
        metrics:{
            type: Object,
            default:null
        }
    },
    data(){
        return{
            timings: this.metrics,
            serverLoading: true,
            appLoading: true,
            browserLoading:true,
        }
    },
    mounted(){
        setTimeout(()=>{
            this.serverLoading= false;
            if(this.$refs.arrow){this.$refs.arrow.style.visibility= "visible";}
        },this.timings.server + 6000)
        setTimeout(()=>{
            this.appLoading= false;
        },this.timings.app + 6000)
        setTimeout(()=>{
            this.browserLoading= false;
            
        },this.timings.browser + 6000)
        
    }
})
</script>
<style>

.b-icon.bi{
    width: 60px;
    background: #fafafa;
}
.icon-div{
    padding: 0 20px;
    background: #fafafa;
}
.background-div{
    height: 160px;
    background: #fafafa;
    width:100%;
}
.dots{
    height: 128px;

}
.dots::after{
    content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";
    font-size:1.5rem;
    overflow: hidden;
    white-space: nowrap;
}
.icon-loader{
    height: 160px;
}
.arrow {
    margin-top: 1rem;
    height: 2px;
    width: 80%;
    background: black;
}
.arrow::after{
    content: '→';
    color: black;
    font-size: 2rem;
    left:99%;
    position: relative;
    transform: translateY(-9%);
}
.icon-description{
    text-align: center;
}
.loading{
    z-index: 3;
    animation: fadein 6s ease forwards ;
    pointer-events: none;
}
.loading-bg{
    width: 30%;
    left: 35%;
    background: white;
    height: 5px;
    border-radius: 10px ;
}
.loading-progress{
    left: 35%;
    background:#079b1d;
    animation: progress 5s ease-in-out forwards;
    height: 5px;
    border-radius: 10px ;
}
.metrics-container{
    width: 70%;
}
@keyframes fadein {
    0% { opacity: 1;}
    25% { opacity: 1;}
    50% { opacity: 1;}
    90% { opacity: 1;}
    100% { opacity: 0;}
}
@keyframes progress {
    0% { width: 10%;}
    25% { width: 15%;}
    50% { width: 20%;}
    75% { width: 25%;}
    100% { width: 30%;}
}
</style>